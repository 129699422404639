// src/components/Level.js
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import QuestionComponent from './QuestionComponent';
import GridComponent from './GridComponent';
import DraggableItem from './DraggableItem';
import CheckButton from './CheckButton';
import Overlay from './Overlay';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { LevelContext } from '../contexts/LevelContext';

import rotateConnections from '../rotateConnections';
import updateBulbStatus from '../updateBulbStatus';
import '../App.css';
import './Level.css';

import { doc, setDoc,getDoc,updateDoc, arrayUnion,increment} from 'firebase/firestore'; // Remove updateDoc
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';

import levels from './Levels.js';
import { isMobile, isTablet } from 'react-device-detect';

// src/components/GridCell.js
import componentDictionary from './componentDictionary'; // Adjust path accordingly

const Level = () => {
  const { levelIndex, exerciseIndex } = useParams();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);
  const { setLevelCompletion } = useContext(LevelContext);

  const { currentUser } = useContext(AuthContext);

  const refreshPage = () => {
    window.location.reload();
  };

  const levelIdx = parseInt(levelIndex, 10);   // Parse the level index from the URL
  const exerciseIdx = parseInt(exerciseIndex, 10);   // Parse the exercise index from the URL

  console.log('levelIndex:', levelIdx, 'exerciseIndex:', exerciseIdx);
  console.log('Levels array:', levels); // Log the levels array

  const currentLevel = levels[levelIdx];
  const currentExercise = currentLevel.exercises[exerciseIdx];

  const { prePlacedComponents = [], draggableItems = [], goal = [], metadata, tags = [], educationalMessages = {} } = currentExercise;
  
  // Map prePlacedComponents and draggableItems to include properties from componentDictionary
  const mappedPrePlacedComponents = prePlacedComponents.map(component => ({
    ...component,
    ...componentDictionary[component.type]
  }));

  const mappedDraggableItems = draggableItems.map(item => ({
    ...item,
    ...componentDictionary[item.type],
    id: item.id,
    itemCount: item.itemCount
  }));


  // Determine grid size based on metadata
  const gridCol = metadata && metadata.gridCol ? metadata.gridCol : 5;
  const gridRow = metadata && metadata.gridRow ? metadata.gridRow : 5;
  const initialGrid = Array(gridRow).fill(null).map(() => Array(gridCol).fill(null));

  const [grid, setGrid] = useState(initialGrid);
  const [bulbStatus, setBulbStatus] = useState({
    'red-light-bulb': "OFF",
    'green-light-bulb': "OFF",
    'yellow-light-bulb': "OFF",
  });
  const [starStatus, setStarStatus] = useState(false);
  const [lastRemovedItemId, setLastRemovedItemId] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const [itemCounts, setItemCounts] = useState(() => {
    return mappedDraggableItems.reduce((acc, item) => {
      acc[item.id] = item.itemCount;
      return acc;
    }, {});
  });

  const [resultMessage, setResultMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const isTouchDevice = isMobile || isTablet;
  const [reset, setReset] = useState(false); // Add reset state

  useEffect(() => {
    const newGrid = [...initialGrid];
    mappedPrePlacedComponents.forEach(component => {
      const rotatedConnections = rotateConnections(component.connections || {}, component.rotation || 0);
      const newComponent = { ...component, prePlaced: true, connections: rotatedConnections };
      newGrid[component.row][component.col] = newComponent;
    });
    setGrid(newGrid);
  }, [levelIdx, exerciseIdx]);

  useEffect(() => {
  // Reset draggable items when the exercise changes
  setItemCounts(
    mappedDraggableItems.reduce((acc, item) => {
      acc[item.id] = item.itemCount;
      return acc;
    }, {})
  );
}, [levelIdx, exerciseIdx]);


  useEffect(() => {
    updateBulbStatus(grid, setBulbStatus,setStarStatus);
    console.log('Bulb status update:', bulbStatus);
  }, [grid]);

  const updateGrid = (row, col, item) => {
    if (grid[row][col] && grid[row][col].prePlaced) {
      return;
    }
    //console.log(`Updating grid at row: ${row}, col: ${col} with item: ${JSON.stringify(item)}`);
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          //console.log(`Placing item at row: ${rowIndex}, col: ${colIndex}`);
          return { ...item, rotation: item.rotation, id: item.id }; // Ensure the item has its ID
        }
        return c;
      })
    );

    setGrid(newGrid);
    //console.log("Updated grid state:", newGrid);
  };

  const rotateItem = (row, col) => {
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col && c) {
          const newRotation = (c.rotation + 90) % 360;
          const newConnections = rotateConnections(c.connections, 90);
          return { ...c, rotation: newRotation, connections: newConnections };
        }
        return c;
      })
    );
    setGrid(newGrid);
  };

  const removeItem = (row, col) => {
    const item = grid[row][col];
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          return null;
        }
        return c;
      })
    );
    setGrid(newGrid);
  if (item) {
    setLastRemovedItemId(item.id);
    setResetCounter(prev => prev + 1);

    setItemCounts(prev => {
      const newCount = { ...prev };
      newCount[item.id] += 1;
      //console.log(`COUNT increase (removed) ${item.type} (${item.id}): ${newCount[item.id]} remaining`);
      return newCount;
    });
  }
};


const handleCheck = () => {
  setShowOverlay(true);
};

const handleContinue = () => {
  setShowOverlay(false);
  handleNextExercise(); 
};

const handleTryAgain = () => {
  setShowOverlay(false);
};

const handleLevelComplete = async (starAchieved) => {
  setLevelCompletion(prevState => {
    const newState = [...prevState];
    
    // Mark current exercise as complete with star status
    newState[levelIdx].exercises = newState[levelIdx].exercises || [];
    newState[levelIdx].exercises[exerciseIdx] = {
      completed: true,
      starAchieved: starStatus  // or use starAchieved if passed as a parameter
    };

    // Check if all exercises in the current level are complete
    const allExercisesCompleted = currentLevel.exercises.every((_, idx) => newState[levelIdx].exercises[idx]?.completed);

    if (allExercisesCompleted) {
      newState[levelIdx].completed = true;
      newState[levelIdx].starAchieved = currentLevel.exercises.every((_, idx) => newState[levelIdx].exercises[idx]?.starAchieved);
    }
    return newState;
  });


  if (currentUser) {
    const userRef = doc(db, 'users', currentUser.uid);
    
    try {
      // Get the user's current progress for this level and exercise
      const userDoc = await getDoc(userRef);
      let userProgress = userDoc.exists() ? userDoc.data().progress || {} : {};

      // Get the current exercise's progress (if exists)
      const currentExerciseProgress = userProgress[levelIdx]?.[exerciseIdx] || { completed: false, starAchieved: false };

      // Calculate points to be added (only add points if there's an improvement)
      let pointsEarned = 0;
      
      if (!currentExerciseProgress.completed) {
        // This is the first time completing the exercise, so add points
        pointsEarned += 10; // Base points for completing the exercise
      }

      if (!currentExerciseProgress.starAchieved && starAchieved) {
        // User is earning a star for the first time
        pointsEarned += 5; // Additional points for earning a star
      }

      // If there's no improvement, don't update Firestore or add points
      if (pointsEarned > 0) {
        // Mark the exercise as complete and update the star status
        const updatedProgress = {
          ...userProgress,
          [levelIdx]: {
            ...(userProgress[levelIdx] || {}),
            [exerciseIdx]: {
              completed: true,
              starAchieved: starAchieved, // Update with the new star status
              timestamp: new Date(),
            },
          },
        };

        // Update Firestore with the new progress and increment points
        await updateDoc(userRef, {
          progress: updatedProgress,
          points: increment(pointsEarned),
        });

        console.log(`Points earned: ${pointsEarned}`);
      } else {
        console.log("No points earned (exercise already completed or star already achieved).");
      }

    } catch (error) {
      console.error('Error updating user progress:', error.message);
    }
  }
};
  



  // Logic to handle the transition to the next exercise or level
  const handleNextExercise = () => {
    const nextExerciseIndex = exerciseIdx + 1;
    if (nextExerciseIndex < currentLevel.exercises.length) {
      navigate(`/level/${levelIndex}/exercise/${nextExerciseIndex}`);
    } else {
      navigate('/learn');
    }
  };

  const [nextAvailableCell, setNextAvailableCell] = useState(null);

const findNextAvailableCell = () => {
  for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
    for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
      if (!grid[rowIndex][colIndex]) {
        return { row: rowIndex, col: colIndex };
      }
    }
  }
  return null;
};

// Call this whenever grid updates to update the next available cell
useEffect(() => {
  setNextAvailableCell(findNextAvailableCell());
}, [grid]);

const placeInNextAvailableCell = (item) => {
  if (nextAvailableCell) {
    const rotatedConnections = rotateConnections(item.connections, item.rotation);

    updateGrid(nextAvailableCell.row, nextAvailableCell.col, { ...item, rotation: item.rotation, connections: rotatedConnections });

    // Decrease the item count
    setItemCounts(prevCounts => {
      const newCounts = { ...prevCounts };
      newCounts[item.id] = prevCounts[item.id] - 1; // Decrease the count by 1
      return newCounts;
    });

    setNextAvailableCell(findNextAvailableCell()); // Update the next available cell after placing the item
  }
};

const resetLevel = () => {
  // Reset the grid to the initial state
  const newGrid = [...initialGrid];
  mappedPrePlacedComponents.forEach((component) => {
    const rotatedConnections = rotateConnections(
      component.connections || {},
      component.rotation || 0
    );
    const newComponent = {
      ...component,
      prePlaced: true,
      connections: rotatedConnections,
    };
    newGrid[component.row][component.col] = newComponent;
  });
  setGrid(newGrid);

  // Reset other state variables
  setBulbStatus({
    'red-light-bulb': 'OFF',
    'green-light-bulb': 'OFF',
    'yellow-light-bulb': 'OFF',
  });
  setStarStatus(false);
  setLastRemovedItemId(null);
  setResetCounter(0);
  setItemCounts(
    mappedDraggableItems.reduce((acc, item) => {
      acc[item.id] = item.itemCount;
      return acc;
    }, {})
  );
  setResultMessage('');
  setErrors([]);
  setReset((prev) => !prev);
};



  return (
    <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={{ enableMouseEvents: true }}>
      <div className="app">
        <div>
        <div className="top-container">
          <button className="exit-button" onClick={resetLevel}>
            <i className="fas fa-undo" style={{fontSize:'24px'}}></i>
          </button>

          {/* Progress Bar Container */}
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${(exerciseIdx + 1) / (currentLevel.exercises.length + 1) * 100}%` }}></div>
          </div>

        <button className="exit-button" onClick={() => navigate('/learn')}>
          <i className="fas fa-times"></i>
        </button>

        </div>

        {/* QuestionComponent directly under top-container */}
        <div className="question-component">
          <QuestionComponent goal={goal} starStatus={starStatus} />
        </div>
        </div>

        <GridComponent grid={grid} setGrid={setGrid} updateGrid={updateGrid} removeItem={removeItem} rotateItem={rotateItem} bulbStatus={bulbStatus} starStatus={starStatus} setItemCounts={setItemCounts} nextAvailableCell={nextAvailableCell} />
        
        <div className="bottom-container">
          <div className="draggable-items">
            {mappedDraggableItems.map((item, index) => (
              <DraggableItem key={index} item={item} reset={lastRemovedItemId === item.id && resetCounter} remainingCount={itemCounts[item.id]} placeInNextAvailableCell={placeInNextAvailableCell} />
            ))}
          </div>
          <CheckButton onCheck={handleCheck} />
        </div>

        {showOverlay && (
          <Overlay
            resultMessage={resultMessage}
            onContinue={handleContinue}
            onTryAgain={handleTryAgain}
            isStarConnected={starStatus}
            goal={goal}
            bulbStatus={bulbStatus}
            exerciseTags={tags} 
            educationalMessages={educationalMessages}
            errors={errors} // Ensure errors is always an array
            onLevelComplete={handleLevelComplete}
          />
        )}
      </div>
    </DndProvider>
  );

};

export default Level;
