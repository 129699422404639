import componentDictionary from './componentDictionary';

const levels = [
  // Level 0 - Getting use to controls
  // Ex 0
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'corner-wire', row: 1, col:0, rotation: 180 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:3, rotation: 0 },
            { type: 'corner-wire', row: 1, col:3, rotation: 90 },
          ],
          draggableItems: [
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              id: 0, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 4,
            gridRow: 2
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "Circuits need to be connected in a closed loop to allow current to flow from the battery to the bulbs and back.",
          failure: "Drag or tap a component from the bottom to place it."
        }
        },
        // Ex 1
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 1, rotation: 90 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
          ],
          draggableItems: [
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              id: 0, itemCount: 1, rotation: 0,resistanceValue: 330
            },
            { type: "corner-wire", id: 1,itemCount: 2,rotation: 180 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 2
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "A closed loop is needed for current to flow through the circuit.",
          failure: "Once placed, tap on a component/wire to rotate it!"
        }
        },
        // Ex 2
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 2, rotation: 90 },
            { type: 'star', row: 2, col: 2, rotation: 0 },
            { type: 'red-light-bulb', row: 1, col: 0, rotation: 270 },
            { type: 'corner-wire', row: 0, col: 3, rotation: 0 },
            { type: 'corner-wire', row: 2, col: 3, rotation: 90 },
            { type: 't-wire', row: 1, col: 3, rotation: 90 },
            { type: 'block', row: 2, col: 0, rotation: 90 },
          ],
          draggableItems: [
            { type: "t-wire", id: 0, itemCount: 1, rotation: 0},
            { type: "straight-wire", id: 1, itemCount: 1,rotation: 90 },
            { type: "corner-wire", id: 2, itemCount: 1,rotation: 180 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              id: 3, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "ON" },
          ],
          metadata:{
            gridCol: 4,
            gridRow: 3
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "The resistor stops too much current getting to the bulbs and breaking them!",
          failure: "Make sure to add a resistor to reduce the current going into the bulbs"
        }
        },
      ]},
  // Level 1 - Introduce switch
  // Ex 0
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              row: 0, col: 1, rotation: 0,resistanceValue: 330
            },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'switch', row: 1, col: 1, rotation: 0 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { type: "corner-wire", id: 1,itemCount: 1,rotation: 180 },
            { type: "corner-wire", id: 2,itemCount: 1,rotation: 90 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 2
        },
        tags: ["resistor","switch"],
        educationalMessages: {
          success: "When a switch is OPEN, the circuit isn't a loop and current can't flow. You closed the switch, allowing current to flow.",
          failure: "Click the switch to turn it on!"
        }
        },
        // Ex 1
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col: 0, rotation: 270 },
            { type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              row: 0, col: 1, rotation: 0,resistanceValue: 330
            },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'star', row: 2, col: 1, rotation: 0 },
            { type: 'yellow-light-bulb', row: 1, col: 2, rotation: 270 },
            { 
              type: 'switch', 
              ...componentDictionary['switch'].initialize("ON"), 
              row: 1, col: 1, rotation: 0 
            },
            { 
              type: 'switch', 
              ...componentDictionary['switch'].initialize("OFF"), 
              row: 2, col: 3, rotation: 0 
            },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
            { type: 'straight-wire', row: 2, col:2, rotation: 90 },
            { type: 't-wire', row: 1, col:4, rotation: 90 },
          ],
          draggableItems: [
            { type: "t-wire", id: 0, itemCount: 1,rotation: 270 },
            { type: "corner-wire", id: 1,itemCount: 1,rotation: 180 },
            { type: "green-light-bulb", id: 2,itemCount: 1,rotation: 180 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 3
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "When a wire splits, the current also splits.",
          failure: "When a wire splits, the current also splits!"
        }
        },
      ]},
  // Level 2 - Introduce resistor
  // Ex 0
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'corner-wire', row: 1, col:0, rotation: 180 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:3, rotation: 0 },
            { type: 'corner-wire', row: 1, col:3, rotation: 90 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              id: 1, itemCount: 1, rotation: 0,resistanceValue: 560
            },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(2), 
              id: 2, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 4,
            gridRow: 2
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "A higher value resistor will reduce the current more, a wire has no resistance so lets through all the current!",
          failure: "Drag the component off the grid to remove it. A higher value resistor will reduce the current more."
        }
        },
        // Ex 1
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              row: 1, col: 1, rotation: 0,resistanceValue: 660
            },
            { type: 'star', row: 0, col: 1, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col: 0, rotation: 270 },
            { type: 't-wire', row: 1, col:0, rotation: 270 },
            { type: 'yellow-light-bulb', row: 1, col: 3, rotation: 270 },
            { type: 't-wire', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
            { type: 'corner-wire', row: 1, col:4, rotation: 90 },
            { type: 'corner-wire', row: 2, col:0, rotation: 180 },
            { type: 'corner-wire', row: 2, col:2, rotation: 90 },
            { type: 'block', row: 2, col: 3, rotation: 90 },
            { type: 'block', row: 2, col: 4, rotation: 90 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              id: 1, itemCount: 1, rotation: 0,resistanceValue: 660
            },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(2), 
              id: 2, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "OFF" },
            { type: 'yellow-light-bulb', status: "ON" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 3
        },
        tags: ["resistor"],
        educationalMessages: {
          success: "Even though each resistor is 660Ω, when they are in parallel, the total resistance is lower (330Ω) because current flows through both resistors at the same time.",
          failure: "These resistors are connected in parallel meaning the current can flow through both at the same time."
        }
        },
      ]},
    // Level 3 - Practice
    // Ex 0
    {exercises: [
      //Ex 0
      {
        prePlacedComponents: [
          { type: 'battery', row: 0, col: 2, rotation: 0 },
          { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
          { type: 'yellow-light-bulb', row: 1, col: 1, rotation: 270 },
          { type: 'straight-wire', row: 1, col: 2, rotation: 90 },
          { type: 'straight-wire', row: 2, col: 2, rotation: 90 },
          { type: 'star', row: 3, col: 2, rotation: 0 },
          { type: 'red-light-bulb', row: 2, col: 0, rotation: 270 },
          { type: 'corner-wire', row: 0, col: 3, rotation: 0 },
          { type: 'corner-wire', row: 3, col: 3, rotation: 90 },
          { type: 't-wire', row: 2, col: 3, rotation: 90 },
          { type: 'block', row: 3, col: 0, rotation: 90 },
        ],
        draggableItems: [
          { type: "t-wire", id: 0, itemCount: 3, rotation: 270},
          { type: "straight-wire", id: 1, itemCount: 2 },
          { type: "corner-wire", id: 2, itemCount: 3,rotation: 180 },
          { 
            type: "resistor",
            ...componentDictionary['resistor'].initialize(0),
            id: 3, itemCount: 1, rotation: 0,resistanceValue: 330
          },
        ],
        goal:[
          { type: 'green-light-bulb', status: "ON" },
          { type: 'yellow-light-bulb', status: "OFF" },
          { type: 'red-light-bulb', status: "ON" },
        ],
        metadata:{
          gridCol: 4,
          gridRow: 4
      },
      tags: ["resistor"],
      educationalMessages: {
        success: "When wires split into multiple paths (parallel circuits) the current also splits.",
        failure: "We dont need to light up the yellow bulb. If we dont connect it, we remove the branch that splits the current."
      }
      },
    ]},
    // Level 4 - Bonus
    // Ex 0
    {exercises: [
      {
        prePlacedComponents: [
          { type: 'battery', row: 0, col: 2, rotation: 0 },
          { type: 'corner-wire', row: 0, col: 0, rotation: 270 },
          { type: 'corner-wire', row: 2, col: 0, rotation: 180 },
          { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
          { type: 'straight-wire', row: 0, col: 1, rotation: 90 },
          { type: 'star', row: 2, col: 3, rotation: 0 },
          { type: "resistor",
            ...componentDictionary['resistor'].initialize(0),
            row: 1, col: 2, rotation: 0,resistanceValue: 330
          },
          { type: "resistor",
            ...componentDictionary['resistor'].initialize(0),
            row: 2, col: 2, rotation: 0,resistanceValue: 330
          },
          { 
            type: 'switch', 
            ...componentDictionary['switch'].initialize("ON"), 
            row: 1, col: 1, rotation: 0 
          },
          { 
            type: 'switch', 
            ...componentDictionary['switch'].initialize("ON"), 
            row: 2, col: 1, rotation: 0 
          },
          { type: 'green-light-bulb', row: 0, col:4, rotation: 90 },
          { type: 't-wire', row: 1, col:4, rotation: 90 },
        ],
        draggableItems: [
          { type: "straight-wire", id: 2,itemCount: 1,rotation: 90 },
          { type: "t-wire", id: 0, itemCount: 1,rotation: 270 },
          { type: "corner-wire", id: 1,itemCount: 1,rotation: 90 },
          
        ],
        goal:[
          { type: 'green-light-bulb', status: "ON" },
          { type: 'yellow-light-bulb', status: "OFF" },
          { type: 'red-light-bulb', status: "OFF" },
        ],
        metadata:{
          gridCol: 5,
          gridRow: 3
      },
      tags: ["resistor"],
      educationalMessages: {
        success: "Even though each resistor is 330Ω, when they are in parallel, the total resistance is lower (165Ω) because current flows through both resistors at the same time. 165Ω is too low and doesnt stop enough current",
        failure: "When the wires splits, the current also splits. When resistors are in parallel, the equivalent resistance is half of one of the resistors."
      }
      },
    ]},
  ];

  export default levels;